import React, { useEffect, useState } from 'react';
import { Download, Trash2, FileBox, Loader2, AlertCircle, AlertTriangle } from 'lucide-react';
import api from '../axiosConfig';  // Update this path based on your file structure
import { useNavigate } from 'react-router-dom';

const Projects = () => {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [deleteConfirmation, setDeleteConfirmation] = useState({ show: false, projectId: null });
    const navigate = useNavigate();

    const fetchProjects = async () => {
        try {
            const response = await api.get('/projects');

            const updatedProjects = await Promise.all(
                response.data.projects.map(async (project) => {
                    // Check if this is a batch job
                    if (project.batch_job_id) {
                        try {
                            const statusResponse = await api.get(`/batch_status/${project.batch_job_id}`);
                            return {
                                ...project,
                                status: statusResponse.data.status,
                                progress: statusResponse.data.processed_rows / statusResponse.data.total_rows * 100
                            };
                        } catch (error) {
                            console.error('Batch status error:', error);
                            return project;
                        }
                    }
                    return project;
                })
            );

            setProjects(updatedProjects);
        } catch (error) {
            console.error('Error fetching projects:', error);
            if (error.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            } else {
                setError("Failed to load projects. Please try again later.");
            }
        } finally {
            setLoading(false);
        }
    };

    // Initial fetch
    useEffect(() => {
        fetchProjects();
    }, []);


    // Polling effect
    useEffect(() => {
        const hasUnfinishedProjects = projects.some(
            project => project.status === "Processing" || project.status === "Pending"
        );

        if (hasUnfinishedProjects) {
            const interval = setInterval(fetchProjects, 5000);
            return () => clearInterval(interval);
        }
    }, [projects]);

    const initiateDelete = (projectId) => {
        setDeleteConfirmation({ show: true, projectId });
    };

    const deleteProject = async () => {
        try {
            await api.delete(`/delete_project/${deleteConfirmation.projectId}`);
            setProjects(projects.filter((project) => project.id !== deleteConfirmation.projectId));
            setDeleteConfirmation({ show: false, projectId: null });
        } catch (error) {
            console.error('Error deleting project:', error);
            if (error.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            } else {
                setError("Failed to delete project. Please try again.");
            }
        }
    };

    // In Projects component, replace the handleDownload function with:

    const handleDownload = async (projectId) => {
        try {
            const response = await api.get(`/download/${projectId}`, {
                responseType: 'blob',
                headers: {
                    'Accept': 'text/csv',
                }
            });

            // Get filename from Content-Disposition header if possible
            const contentDisposition = response.headers['content-disposition'];
            let filename = 'download.csv';
            if (contentDisposition) {
                const match = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
                if (match) {
                    filename = match[1].replace(/['"]/g, '');
                }
            }

            // Create and trigger download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();

            // Cleanup
            window.URL.revokeObjectURL(url);
            link.remove();
        } catch (error) {
            console.error('Download error:', error);
            setError("Failed to download project file.");
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${day}.${month}.${year}, ${hours}:${minutes}`;
    };

    const StatusBadge = ({ status, progress }) => {
        const statusStyles = {
            'Done': 'bg-green-100 text-green-700 border-green-200',
            'Processing': 'bg-blue-100 text-blue-700 border-blue-200',
            'Failed': 'bg-red-100 text-red-700 border-red-200',
            'Pending': 'bg-yellow-100 text-yellow-700 border-yellow-200'
        };

        return (
            <div className="flex items-center gap-2">
            <span className={`px-3 py-1 rounded-full text-sm font-medium border ${statusStyles[status] || 'bg-gray-100 text-gray-700 border-gray-200'}`}>
                {status}
            </span>
                {progress !== null && (
                    <span className="text-sm text-slate-600">
                    {Math.round(progress)}%
                </span>
                )}
            </div>
        );
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="flex flex-col items-center gap-4">
                    <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
                    <p className="text-slate-600 font-medium">Loading projects...</p>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="flex items-center gap-3 text-red-500">
                    <AlertCircle className="w-6 h-6" />
                    <p className="font-medium">{error}</p>
                </div>
            </div>
        );
    }

    return (
        <div className="bg-white rounded-xl shadow-sm p-6 max-w-6xl mx-auto">
            <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold text-slate-800">Projects</h2>
                <div className="text-sm text-slate-500">
                    Total Projects: {projects.length}
                </div>
            </div>

            {projects.length === 0 ? (
                <div className="flex flex-col items-center justify-center py-12 text-slate-500">
                    <FileBox className="w-12 h-12 mb-4" />
                    <p className="text-lg font-medium">No projects yet</p>
                    <p className="text-sm mt-2">Create a new project to get started</p>
                </div>
            ) : (
                <div className="max-h-[calc(100vh-12rem)] overflow-y-auto scrollbar-thin scrollbar-thumb-slate-200 scrollbar-track-slate-50">
                    <table className="w-full border-collapse">
                        <thead className="bg-slate-50 sticky top-0">
                        <tr>
                            <th className="px-6 py-4 text-left text-sm font-medium text-slate-600">Project Name</th>
                            <th className="px-6 py-4 text-left text-sm font-medium text-slate-600">Status</th>
                            <th className="px-6 py-4 text-left text-sm font-medium text-slate-600">Date & Time</th>
                            <th className="px-6 py-4 text-right text-sm font-medium text-slate-600">Actions</th>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-slate-100">
                        {projects.map((project) => (
                            <tr
                                key={project.id}
                                className="group hover:bg-slate-50 transition-colors duration-150"
                            >
                                <td className="px-6 py-4">
                                    <div className="flex items-center gap-3">
                                        <FileBox className="w-5 h-5 text-slate-400" />
                                        <span className="font-medium text-slate-700">{project.file_name}</span>
                                    </div>
                                </td>
                                <td className="px-6 py-4">
                                    <StatusBadge
                                        status={project.status}
                                        progress={project.progress}
                                    />
                                </td>
                                <td className="px-6 py-4 text-slate-600">
                                    {formatDate(project.created_at)}
                                </td>
                                <td className="px-6 py-4">
                                    <div className="flex items-center justify-end gap-3">
                                        {project.status === "Done" && (
                                            <>
                                                <button
                                                    onClick={() => handleDownload(project.id)}
                                                    className="p-2 text-blue-600 hover:bg-blue-50 rounded-lg transition-colors duration-150"
                                                    title="Download"
                                                >
                                                    <Download className="w-5 h-5" />
                                                </button>
                                                <button
                                                    onClick={() => initiateDelete(project.id)}
                                                    className="p-2 text-red-600 hover:bg-red-50 rounded-lg transition-colors duration-150"
                                                    title="Delete"
                                                >
                                                    <Trash2 className="w-5 h-5" />
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}

            {/* Delete Confirmation Modal */}
            {deleteConfirmation.show && (
                <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
                    <div className="bg-white rounded-xl p-6 max-w-md w-full shadow-xl">
                        <div className="flex flex-col items-center text-center">
                            <div className="h-12 w-12 rounded-full bg-red-100 flex items-center justify-center mb-4">
                                <AlertTriangle className="h-6 w-6 text-red-600" />
                            </div>
                            <h3 className="text-lg font-semibold text-slate-900 mb-2">
                                Confirm Deletion
                            </h3>
                            <p className="text-slate-500 mb-6">
                                Are you sure you want to delete this project? This action cannot be undone.
                            </p>
                            <div className="flex gap-3 w-full">
                                <button
                                    onClick={() => setDeleteConfirmation({ show: false, projectId: null })}
                                    className="flex-1 px-4 py-2 border border-slate-200 text-slate-700 rounded-lg hover:bg-slate-50 transition-colors duration-150 font-medium"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={deleteProject}
                                    className="flex-1 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors duration-150 font-medium"
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Projects;